import siteMixin from "@/mixins/siteMixin";
export default {
    methods: {
        getCurrentUser: function () {
            return sessionStorage.getObject("user") || localStorage.getObject("user");
        },
        getAuth: function () {
            let user = sessionStorage.getObject("user") || localStorage.getObject("user");
            return user.auth;
        },
        logout: function (reload = true) {
            delete sessionStorage.user;
            delete localStorage.user;
            if (reload) {
                window.location.reload();
            }
        },
        getSubscriberStats: function () {
            let authUser = this.getCurrentUser();
            return authUser.data.subscriber_stats;
        },
        getCurrentUserPermissions: function () {
            let default_permissions = {
                events: 0,
                agendas: 0,
                notices: 0,
                notifications: 0,
                pages: 0,
                departments: 0,
            };
            let currentUser = this.getCurrentUser();
            if (!currentUser) {
                return;
            }
            if (!currentUser.data) {
                return default_permissions;
            } else {
                return currentUser.data.dashboard_permissions ? currentUser.data.dashboard_permissions : default_permissions;
            }
        },
        getCurrentUserRoles: function () {
            let currentUser = this.getCurrentUser();
            if (!currentUser || !currentUser.data || !currentUser.data.roles) {
                return [];
            }

            return currentUser.data.roles;
        },
        getIsStuff: function () {
            let currentUser = this.getCurrentUser();
            if (!currentUser) {
                return;
            }
            if (currentUser.data.email) {
                let email = currentUser.data.email;
                email = email.split("@");
                if (email[1].includes("townweb.com") || email[1].includes("heygov.com") || email[1].includes("hejdev.com") || email[1].includes("hejdev.rs") || email[1].includes("tweaksocialmedia.com")) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (currentUser.data.roles.includes("SuperAdmin")) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        userHasCms6Permission(permission, model = null, modelId = null) {
            
            let currentUser = this.getCurrentUser();
            if (!currentUser) {
                return;
            }
            if (!currentUser.data) {
                return false;
            } else {
                if (!currentUser.data.roles) {
                    return false;
                }
                
                if (currentUser.data.roles.includes("SuperAdmin")) {
                    return true;
                }
                
                if (currentUser.data.permissions && currentUser.data.permissions.includes(permission)) {
                    return true;
                } else if (model && currentUser.data.editoriables && currentUser.data.editoriables[model] && currentUser.data.editoriables[model].includes(modelId)) {
                    return true;
                } else if (model && currentUser.data.editoriables && currentUser.data.editoriables[model] && !modelId) {
                    return true;
                }

                return false;
            }
        },
        userHasPermission(permission, wp = false, typeOfSite = null, model = null, modelId = null) {

            if (!typeOfSite) {
                typeOfSite = this.siteType
            }
            if (wp && typeOfSite == 'wordpress') {
                let permissions = this.getCurrentUserPermissions();
                return permissions ? (permissions[wp] === 0) : false;
            } else if (typeOfSite == 'cms6') {
                return this.userHasCms6Permission(permission, model, modelId);
            } else if (typeOfSite == 'wordpress') {
                return true;
            } else {
                return false;
            }
        },
        userCanCloseOverdueWarning()
        {
            return this.getCurrentUserRoles().includes('administrator') || this.getCurrentUserRoles().includes('SuperAdmin'); // #1 for wordpress - #2 for cms6
        },
        getClientUsers() {
            let authUser = this.getCurrentUser();
            return authUser.data.client_users;
        }
    },
    computed: {
        siteType() {
            return this.getSiteType() || [];
        }
    },
    mixins: [siteMixin],
};
